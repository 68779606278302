import React from 'react';
import '../css/App.css';

import Dashboard from './Container/Dashboard/Dashboard';
import Login from './Container/Login';
import Register from './Container/Register';
import Products from './Container/Products';
import Organizations from './Container/Organizations';
import Middleware from './Container/Middleware';
import Account from './Container/Account';
import VolumetricDemo from './Container/VolumetricDemo';
import Logs from './Container/Logs';
import Admin from './Container/Admin';
import Sensors from './Container/Sensors';
import ConsumerConnect from './Container/ConsumerConnect';
import ConnectionStatistics from './Container/ConnectionStatistics';
import OrganizationInfo from './Container/OrganizationInfo';
import ResetPassword from './Container/ResetPassword';
import EmailCheck from './Container/EmailCheck';
import DataEntry from './Container/DataEntry';
import Privacy from './Container/Privacy';
import Terms from './Container/Terms';
import DataCleaning from './Container/DataCleaning';
import CleaningPage from './Container/CleaningPage';
import Calibration from './Container/Calibration';
import Simulator from './Container/Simulator';

import ErrorBoundary from './Components/ErrorBoundary';

import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";

import { ThemeProvider } from '@material-ui/styles';
import {theme} from './style';
import AppEcom from './Container/AppEcom';



function App() {
  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <div>
          <ErrorBoundary>
          <Switch>
            <Route path="/register" component={Register} />
            <Route path="/login" component={Login} />
            <Route path="/resetpassword/:token" component={ResetPassword} />
            <Route path="/recover" component={EmailCheck} />
            <Route path="/organization/:orgId" component={OrganizationInfo} />
            <Route path="/dashboard/:orgId/:productId" component={Dashboard} />
            <Route path="/products" component={Products} />
            <Route path="/middleware" component={Middleware} />
            <Route path="/organizations" component={Organizations} />
            <Route path="/account" component={Account} />
            <Route path="/volumetric" component={VolumetricDemo} />
            <Route path="/messages/:productId?" component={ConsumerConnect} />
            <Route path="/logs" component={Logs} />
            <Route path="/admin" component={Admin} />
            <Route path="/sensors" component={Sensors} />
            <Route path="/datacleaning/:datasetId" component={CleaningPage} />
            <Route path="/datacleaning" component={DataCleaning} />
            <Route path="/calibration" component={Calibration} />
            <Route path="/simulator" component={Simulator} />
            <Route path="/dataentry" component={DataEntry} />
            <Route path="/connectionstatistics/:productId?" component={ConnectionStatistics} />
            <Route path="/privacy" component={Privacy} />
            <Route path="/terms" component={Terms} />
            <Route path="/app/ecom/:userId/:productId/:providerId" component={AppEcom} />
            <Route><Redirect to="/login"></Redirect></Route>
          </Switch>
          </ErrorBoundary>
        </div>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
